<template>
  <div>
    <div class="banner">
      <div class="conteudo-interno">
        <div class="nova-comunicacao-container">
          <h1>Cadastro de Prêmios</h1>
          <div class="container-btns" v-if="perfil != 5 && perfil != null">
            <router-link class="btn btn-laranja" :to="{ name: 'AdicionarPremio' }">Novo Prêmio</router-link>
          </div>
          <form @submit.prevent class="formulario-container">
            <div class="formulario-linha flex">
              <div class="formulario-item medio">
                <label class="formulario-item-label">Campanha</label>
                <multiselect v-model="form.campanha" label="titulo" track-by="titulo" :options="titulo"
                  :searchable="true" :multiple="false" placeholder="" select-label="Selecionar" deselect-label="Remover"
                  selected-label="Selecionado" />
              </div>

              <div class="formulario-item medio">
                <label class="formulario-item-label">Descrição</label>
                <multiselect v-model="form.descricao" label="Descrição" track-by="id" :options="descricao"
                  :searchable="false" :multiple="false" placeholder="" select-label="Selecionar"
                  deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
              </div>

              <div class="formulario-item medio">
                <label class="formulario-item-label">Tipo de Prêmio</label>
                <multiselect v-model="form.tipoPremio" label="nome" track-by="nome" :options="tipoPremio"
                  :searchable="false" :multiple="false" placeholder="" select-label="Selecionar"
                  deselect-label="Remover" selected-label="Selecionado" />
              </div>
            </div>
          </form>

          <div class="container-btns">
            <button class="btn btn-cancelar" @click="limparFiltro">
              Limpar
            </button>
            <button class="btn btn-laranja" @click="listar()">Filtrar</button>
          </div>

          <transition name="fade-left" mode="out-in">
            <div class="paginacao" v-if="!semDados">
              <button :disabled="paginaBusca == 1" @click="pagAnterior">
                Anterior
              </button>
              <p>{{ paginaBusca }}</p>
              <button :disabled="ultimoItem" @click="proxPagina">
                Próxima
              </button>
            </div>
          </transition>
          <lista-premios :dados="listaCampanhas" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  listarCampanhasFiltro,
  tituloCampanhas,
  listaPremios,
  //   detalhesCampanha,
} from "@/services/campanhas";
import Multiselect from "vue-multiselect";
import ListaPremios from "./ComponenteListaDePremios.vue";

export default {
  name: "CadastroBannerTreinamentosListar",
  components: {
    Multiselect,
    ListaPremios,
  },

  data() {
    return {
      titulo: [],
      semDados: true,
      paginaBusca: 1,
      ultimoItem: false,
      dadosTabela: [],
      descricao: [],
      listaCampanhas: [],
      form: {
        campanha: [],
        descricao: [],
        tipoPremio: [],
      },
      tipoPremio: [
        { id: 1, nome: "Pontos" },
        { id: 2, nome: "Produtos" },
      ],
      perfil: null
    };
  },

  mounted() {
    this.listaTitulosCampanhas();
    this.perfil = localStorage.getItem('perfil');
  },
  methods: {
    buscarPremios() {
      listaPremios().then((resp) => {
        if (resp.data.lista.length > 0) {
          this.listaCampanhas.push(JSON.parse(JSON.stringify(resp.data.lista)));
        }
      });

      // this.titulo.forEach(item => {
      // 	buscaPremios(item.id).then(resp => {
      // 		if (resp.data.lista.length > 0) {
      // 			detalhesCampanha(item.id).then(resp => {
      // 				this.listaCampanhas.push(JSON.parse(JSON.stringify(resp.data)));
      // 			});
      // 		}
      // 	});
      // });
    },

    listaTitulosCampanhas() {
      tituloCampanhas().then((resp) => {
        this.titulo = resp.data;
        this.buscarPremios();
      });
    },

    limparFiltro() {
      (this.form = {
        titulo: "",
        idsRegiao: [],
        idCargo: [],
      }),
        (this.assistenciaSelecionados = []);
      this.form.idsAutorizada = [];
      this.listar();
    },
    listar(pag) {
      const data = {
        titulo: this.form.titulo,
        pagina: pag,
        qtdPorPagina: 10,
      };

      listarCampanhasFiltro(data).then((resp) => {
        this.dadosTabela = [];
        if (resp.data.lista.length == 0) {
          this.treinamentos = [];
          if (pag && pag > 1) {
            this.ultimoItem = true;
          } else {
            this.semDados = true;
          }
        } else {
          resp.data.lista.length < 10
            ? (this.ultimoItem = true)
            : (this.ultimoItem = false);
          if (pag) {
            this.paginaBusca = pag;
          }
          this.semDados = false;
          this.dadosTabela = resp.data.lista;
        }
      });
    },
    pagAnterior() {
      const pag = --this.paginaBusca;
      this.listar(pag);
    },
    proxPagina() {
      const pag = ++this.paginaBusca;
      this.listar(pag);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss">
.banner {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .tabela-linha-btns {
    min-width: 50px;
    max-width: 50px;
  }

  .flex {
    display: flex;
    justify-content: flex-start;
  }

  .medio {
    width: 33.33% !important;
  }
}
</style>
